import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Link } from 'react-router-dom';

import Header from '@cimpress-technology/react-platform-header';
import CssLoader from '@cimpress/react-components/lib/CssLoader';
import Snackbar from '@cimpress/react-components/lib/Snackbar';

import auth from '../auth';
import messages from './messages';
import { SnackBarContext } from './shared/snackbarContext';

const Shell = props => {
  const { formatMessage } = useIntl();
  const homeLink = <Link to="/">{formatMessage(messages.home)}</Link>;

  const queryCache = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
    [],
  );

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarDetails, setSnackbarDetails] = useState({});

  const snackbarSetters = useMemo(
    () => ({ setShowSnackbar, setSnackbarDetails }),
    [setShowSnackbar, setSnackbarDetails],
  );

  const snackbar = (
    <Snackbar
      status={snackbarDetails.type}
      delay={3000}
      show={showSnackbar}
      onHideSnackbar={() => setShowSnackbar(false)}
    >
      {snackbarDetails.details}
    </Snackbar>
  );

  return (
    <CssLoader>
      <Header
        responsive
        accessToken={auth.getAccessToken()}
        appTitle={<Link to="/">{formatMessage(messages.appName)}</Link>}
        appLinks={[{ id: 'home', content: homeLink, type: 'default' }]}
        profile={auth.getProfile()}
        onLogInClicked={() => auth.login()}
        onLogOutClicked={() => auth.logout('/')}
        isLoggedIn={auth.isLoggedIn()}
        showLeftNav
      />
      <QueryClientProvider client={queryCache}>
        <SnackBarContext.Provider value={snackbarSetters}>
          {snackbar}
          <div style={{ minHeight: 'calc(100vh - 225px)' }}>{props.children}</div>
        </SnackBarContext.Provider>
      </QueryClientProvider>
    </CssLoader>
  );
};

Shell.propTypes = {
  children: PropTypes.object.isRequired,
};

export default Shell;
